






















import Vue from 'vue';

export default Vue.extend({
  name: 'Instructions',
  props: {
    mapImg: {
      type: Function,
      required: true,
    },
  },
});
